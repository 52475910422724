<template>
  <b-overlay
    :show="!cargando"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <b-card
      aria-hidden="true"
      class="mb-0 pt-50"
    >
      <b-form>
        <!-- Personalidad Info: Input Fields -->
        <b-row>

          <!-- field: DESCRIPCIÓN -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Nombre de la plantilla *"
              label-for="descripcion"
            >
              <b-form-input
                id="descripcion"
                v-model="personalidad.descripcion"
                placeholder="Ingresa el nombre"
                :state="v$.personalidad.descripcion.$error === true
                ? false
                : null"
                @blur.native="v$.personalidad.descripcion.$touch"
              />
              <!-- Mensajes Error Validación -->
              <b-form-invalid-feedback
                v-if="v$.personalidad.descripcion.$error"
                id="descripcionInfo"
                class="text-right"
              >
                <p v-for="error of v$.personalidad.descripcion.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <!-- field: GRUPO DE CONCEPTOS -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Gupo de conceptos *"
              label-for="id_grupo_conceptos"
            >
              <v-select
                id="id_grupo_conceptos"
                v-model="personalidad.id_grupo_conceptos"
                placeholder="Selecciona el grupo de conceptos..."
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :reduce="option => option.id_grupo_conceptos"
                :options="optionsGrupoConceptos"
                :class="v$.personalidad.id_grupo_conceptos.$error === true
                  ? 'border-danger rounded'
                  : ''"
              />
                <!-- :disabled="optionsPaises.length === 0" -->
              <div
                v-if="v$.personalidad.id_grupo_conceptos.$error"
                id="id_grupo_conceptosInfo"
                class="text-danger text-right"
                style="font-size: 0.857rem;"
              >
                <p v-for="error of v$.personalidad.id_grupo_conceptos.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </div>
            </b-form-group>
          </b-col>

          <!-- field: GRUPO DE CONCEPTOS -->
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Curso(s)"
              label-for="id_grupo_conceptos"
            >
              <b-alert
                v-if="false"
                class="pl-50 pt-25 pb-25 pr-50 mt-50 mb-25 text-center"
                variant="primary"
                show
              >
                El establecimiento "{{ this.getUser.nombre_establecimiento }}", no tienen cursos ni asignaturas creados en este periodo.
              </b-alert>
              <b-overlay
                v-else
                :show="false"
                spinner-variant="primary"
                variant="semi-dark"
              >
                <v-select
                  v-model="personalidad.id_cursos"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :placeholder="optionsCursos.length === 0
                  ? 'No existen cursos disponibles...'
                  : 'Selecciona uno o varios curso...'"
                  :reduce="option => option.id_cursos"
                  :options="optionsCursos"
                  :class="v$.personalidad.id_cursos.$error === true
                    ? 'border-danger rounded '
                    : ''"
                  :disabled="optionsCursos.length === 0"
                  :closeOnSelect="false"
                />

                <div
                  v-if="v$.personalidad.id_cursos.$error"
                  id="asistentesInfo"
                  class="text-danger text-right"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.personalidad.id_cursos.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </b-overlay>
            </b-form-group>
          </b-col>



        </b-row>

        <colLinea />

        <b-card-title class="text-left">
          Indicadores
        </b-card-title>

        <div>
          <div>
            <b-form
              ref="form"
              :style="{height: trHeight}"
              class="repeater-form"
              @submit.prevent="repeateAgain"
            >

              <!-- Row Loop -->
              <b-row
                v-for="(indicador, index) in personalidad.indicadores"
                :id="indicador.id"
                :key="indicador.id"
                ref="row"
              >

                <!-- <b-col md="1"></b-col> -->
                <!-- Item Name -->
                <b-col md="10">
                  <b-form-group
                    label="Descripción del Indicador"
                    label-for="indicador-descripcion"
                  >
                    <b-form-textarea
                      v-model="indicador.descripcion"
                      id="indicador-descripcion"
                      type="text"
                      placeholder="Ingresa la descripción del indicador"
                    />
                  </b-form-group>
                </b-col>

                <!-- Remove Button -->
                <b-col
                  lg="2"
                  md="2"
                  class="mb-50 text-right"
                >
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Eliminar</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr class="mt-0">
                </b-col>
              </b-row>

            </b-form>
          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            @click="repeateAgain"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>Agregar</span>
          </b-button>
        </div>

        <!-- Action Buttons -->
        <colLinea class="mt-2"/>
        <b-row>
          <b-col
            cols="12"
            md="10"
          >
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <btnSubmit
              v-if="cargando"
              class="float-right"
              variant="primary"
              :btnText="btnSubmit"
              :modulo="nombre_permiso"
              @processBtn="submitOption"
            />
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>

// Etiquetas //
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BFormTextarea,
  BMedia, BButton, BAvatar, BOverlay, BCard, BInputGroup, BInputGroupPrepend,
  BFormCheckbox, BAlert, BCardTitle,
} from 'bootstrap-vue'

import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

import vSelect from 'vue-select'

// Imágen //
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

import { mapGetters, mapActions } from 'vuex'

// Validaciones //
import useVuelidate from '@vuelidate/core'
import { required
  , maxLength, email, helpers } from '@vuelidate/validators'

// Componentes //
import btnSubmit from '../../../../components/Form/btnSubmit.vue'
import colLinea from '../../../../components/Form/colLinea.vue'

export default {
  components: {
    BRow,
    BFormTextarea,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BMedia,
    BButton,
    BAvatar,
    vSelect,
    BOverlay,
    BCard,
    BFormCheckbox,
    BAlert,
    BCardTitle,

    btnSubmit,
    colLinea,
  },
  data() {
    return {
      cargando: true,
      nombre_permiso: 'plantillaPerso',
      optionsGrupoConceptos: [],
      optionsCursos: [],
      // indicadores: [{
      //   id: 1,
      //   descripcion: '',
      // }],

      nextTodoId: 2,
    }
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    personalidad: {
      type: Object,
      required: true,
    },
    btnSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Plantilla',
    },
  },
  validations() {
    return {
      personalidad: {
        descripcion: {
          $autoDirty: false,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 255 caracteres.'
            , maxLength(255)),
        },
        id_grupo_conceptos: {
          $autoDirty: false,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        id_cursos : {
          required: helpers.withMessage('El campo es requerido.', required),
        },
      }
    }
  },
  computed: {
    ...mapGetters({
      getConceptos: 'conceptos/getConceptos',
      getCursosPersonaRol: 'cursos/getCursosPersonaRol',
      getUser: 'auth/user',
    }),

    // Vuexy
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    disabledExport() {
      return this.chkCount()
    },
  },
  watch: {
  },
  mounted() {
    this.initTrHeight()
    this.cargarConceptos()
    this.cargarCursosUsuario(this.getUser.id_persona_rol)
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    ...mapActions({
      fetchConceptos: 'conceptos/fetchConceptos',
      fetchCursosPersonaRol: 'cursos/fetchCursosPersonaRol',
    }),
    cargarCursosUsuario(id_persona_rol) {
      // this.cargando_cursos = true
      this.fetchCursosPersonaRol(id_persona_rol).then(() => {
        this.id_curso_selected = null
        this.optionsCursos = []
        if (this.getCursosPersonaRol.length !== 0) {
          this.id_curso_selected = this.getCursosPersonaRol[0]['id']

          this.getCursosPersonaRol.forEach(curso => {
            const nombre = curso.nombre+' '+curso.letra
            this.optionsCursos.push({
              id_cursos: curso.id,
              title: nombre,
              id_tipo_ensenanza: curso.id_tipo_ensenanza,
              disabled: true,
            })
          })
          this.cargando_cursos_horario = false
        }
      })
    },
    cargarConceptos() {
      this.fetchConceptos().then(() => {
        console.log('this.getConceptos :', this.getConceptos)
        this.optionsGrupoConceptos = []
        this.getConceptos.forEach(concepto => {
          this.optionsGrupoConceptos.push({
            id_grupo_conceptos: concepto.id,
            title: concepto.descripcion,
            valores: concepto.valores
          })
        })
      })
    },

    submitOption() {
      this.v$.personalidad.$touch()
      if (!this.v$.personalidad.$invalid) {
        let data = {}
        data.descripcion = this.personalidad.descripcion
        data.id_cursos = this.personalidad.id_cursos
        data.indicadores = []
        this.personalidad.indicadores.forEach(indicador => {
          data.indicadores.push({
            indicador: indicador.descripcion,
            id_concepto: this.personalidad.id_grupo_conceptos
          })
        })
        this.$emit('processForm', data)
      }
    },

    // REPEAT
    repeateAgain() {
      this.personalidad.indicadores.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      console.log('this.personalidad.indicadores :', this.personalidad.indicadores)
      if (this.personalidad.indicadores.length === 1) {

        return false
      }
      this.personalidad.indicadores.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
